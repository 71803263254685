import React from 'react';
import './Projects.css';

function Projects() {
  return (
    <div className="section projects">
      <h2>Projects</h2>
      <p>This is the projects section.</p>
    </div>
  );
}

export default Projects;
