import React from 'react';
import './Skill.css';

function Skill() {
  return (
    <div className="section skill">
      <h2>My Skills</h2>
      <p>This is the skill section.</p>
    </div>
  );
}

export default Skill;
