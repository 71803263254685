import React from 'react';
import './Resume.css';

function Resume() {
  return (
    <div className="section resume">
      <h1>Resume Section</h1>
    </div>
  );
}

export default Resume;
