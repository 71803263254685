import React, { useState } from 'react';
import './Header.css';

function Header() {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  return (
    <header
      className="header"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/image/profile.png)`,
      }}
    >
      <div className="left-section">
      <div className="profile">
        <h1>Tae Heon Kim</h1>
      </div>
      <div className="summary-section">
        <p className="summary">
          Dedicated and innovative Data Science student at UC Berkeley with a foundation in web development
          and research. Passionate about leveraging data-driven solutions.
        </p>
        <button className="read-more" onClick={handleModalOpen}>Read More</button>
        <div className="social-links">
          <a href="https://github.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-github"></i>
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
      </div>
      {isModalOpen && (
        <div className="modal-overlay" onClick={handleModalClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Professional Summary</h2>
            <p>
              Dedicated and innovative Data Science student at UC Berkeley with a strong foundation in web
              development and research. Passionate about leveraging data-driven solutions and cutting-edge
              technologies to solve complex problems. Experienced in developing user-friendly web applications and
              conducting data analysis across various domains. Seeking opportunities to apply my skills in a challenging
              role that allows for continuous learning and growth in the tech industry.
            </p>
            <button className="close-button" onClick={handleModalClose}>Close</button>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
